<template>
  <div>
    <layout-vertical />
    <b-row />
    <section>
      <b-row>
        <b-col>
          <div><p> &nbsp; </p></div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="1" />
        <b-col cols="5">
          <quill-editor-custom :predictions="predictions" />
          <b-button-group>
            <b-button
              v-ripple.400="'rgba(30, 30, 30, 0.15)'"
              v-b-tooltip.hover.v-dark
              pill
              class="find"
              variant="primary"
              title="Click to evaluate your text"
              style="float: left"
              @click="fetchPredictions"
            >
              Find
            </b-button>
          </b-button-group>
        </b-col>
        <b-col cols="1" />
        <b-col cols="3">
          <!-- HOVER VERSION -->
          <!--           <app-collapse
            accordion
            :type="collapseType"
            hover
          > -->

          <app-collapse
            accordion
            :type="collapseType"
          >
            <!-- ***************BUTTONS****************** -->
            <b-button-group>
              <b-button
                v-ripple.400="'rgba(30, 30, 30, 0.15)'"
                v-b-tooltip.hover.v-dark
                pill
                size="sm"
                title="Start a new page"
                variant="primary"
                @click="clearQuillEditor"
              >
                New Document
              </b-button>
              <b-button
                v-show="resultsShowing"
                size="sm"
                variant="outline-primary"
                pill
                @click="clearPredictions"
              >
                Clear All Suggestions
              </b-button></b-button-group>
            <div
              class="progressbar"
            />
            <b-spinner
              v-if="processing"
              class="pulse"
              type="grow"
              label="Loading..."
            />
            <b-img
              v-if="showStandbyimage"
              :src="standbyImg"
              fluid
              center
              height="1000px"
              width="500px"
              alt="No results to show"
            />
            <b-img
              v-if="showNoBiasDetectedimage"
              :src="noBiasDetectedImg"
              fluid
              center
              height="1000px"
              width="500px"
              alt="No results to show"
            />
            <!-- *********************************ACCORDION ************************** -->
            <app-collapse-item
              v-for="(pred, key) in predictions"
              :key="pred.sequence"
              v-for-callback="{key: key, array: predictions, callback: showHighlight}"
              :title="sentenceChunck(pred.sequence)"
              :bias="biasChunck(pred.labels[0])"
            >
              <p class="detail-title">
                Sentence:
              </p><p class="detail-body">
                <span class="sequence">{{ pred.sequence }}</span>
              </p>
              <p />
              <p class="detail-title">
                Bias Description:
              </p>
              <p class="detail-body">
                {{ getBiasDetail(pred.labels[0]).short_description }}
              </p>
              <p /><!--
              <p class="detail-title">
                Confidence:
                {{ (pred.scores[0] * 100).toFixed(0) }}% </p> -->

              <b-button-group style="float: right; margin-bottom: 20px">
                <b-button
                  v-ripple.400="'rgba(30, 30, 30, 0.15)'"
                  v-b-tooltip.hover.v-dark
                  title="Correct Bias"
                  variant="outline-primary"
                  class="btn-icon rounded-circle"
                  @click="logFeedback(pred.sequence,pred.labels[0],pred.scores[0], 'right'); showToast('primary')"
                >
                  <feather-icon
                    icon="ThumbsUpIcon"
                    class="feedback expander"
                  /></b-button>
                <b-button
                  v-ripple.400="'rgba(30, 30, 30, 0.15)'"
                  v-b-tooltip.hover.v-dark
                  title="Incorrect Bias"
                  variant="outline-primary"
                  class="btn-icon rounded-circle"
                  @click="logFeedback(pred.sequence,pred.labels[0],pred.scores[0], 'wrong'); showToast('primary')"
                >
                  <feather-icon
                    icon="ThumbsDownIcon"
                    class="feedback expander"
                  /></b-button>
              </b-button-group>
              <p>
                <span
                  class="expander"
                  @click="learnmore = true"
                >Learn More </span>
              </p>

              <div v-if="learnmore">
                <p> &nbsp; </p>
                <p class="detail-title">
                  Why it happens:
                </p>
                <p class="detail-body">
                  {{ getBiasDetail(pred.labels[0]).why_it_happens }}
                </p>
                <p />
                <p class="detail-title">
                  How to avoid it:
                </p>
                <p class="detail-body">
                  {{ getBiasDetail(pred.labels[0]).how_to_avoid_it }}
                </p>
                <p />
                <p class="detail-title">
                  Related Biases:
                </p>
                <p class="detail-body">
                  {{ getBiasDetail(pred.labels[0]).related_biases }}
                </p>
                <p />
                <!--  <p class="detail-title">
                  Read more:
                </p>
                {{ getBiasDetail(pred.labels[0]).read_more }}
                <p /> -->
                <p>
                  <span
                    class="expander"
                    @click="learnmore = false"
                  >Show less </span>
                </p>
              <!--               <b-button
                v-ripple.400="'rgba(30, 30, 30, 0.15)'"
                v-b-tooltip.hover.v-dark
                title="Delete Card"
                variant="primary"
                style="float: right; margin-bottom: 20px; margin-right: 10px"
                @click="logFeedback(pred.sequence,pred.labels[0],pred.scores[0], 'deleted'); showToast('primary')"
              >
                <feather-icon
                  icon="Trash2Icon"
                  class="mr-50 expander"
                /></b-button> -->
              </div>
            </app-collapse-item>

          </app-collapse>
        </b-col>
        <!-- **********************************   METRIC ******************************-->
        <b-col
          cols="2"
          class="metrics"
        ><div class="metrics-container">
          <!-- Long form FeedBack -->
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            v-b-modal.modal-select2
            pill
            size="sm"
            block
            variant="primary"
            class="sendfeedback"
          >
            Send Feedback
          </b-button>
          <!--         <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="outline-primary"
          @click="tourStart"
        >
          Start Tour
        </b-button>

        <app-tour :steps="steps" /> -->
          <p> &nbsp; </p>
          <statistic-card-horizontal
            icon="PercentIcon"
            :statistic="score()"
            statistic-title="Overall Score"
            color="info"
            class="metrics"
          /><p> &nbsp; </p>
          <statistic-card-horizontal
            icon="EyeIcon"
            :statistic="predictions.length"
            statistic-title="Number of Bias Detected"
            color="info"
            class="metrics"
          /><p> &nbsp; </p>
          <statistic-card-horizontal
            v-if="topOccuringBias()"
            color="warning"
            icon="MessageSquareIcon"
            :statistic="topOccuringBias()"
            statistic-title="Most Common Bias"
            class="most-common-bias"
          />
          <b-form-spinbutton
            v-model="confidence"
            v-b-tooltip.hover.v-dark
            title="95% and Up Recommended"
            min="0.85"
            max="1"
            step="0.01"
          />
          <statistic-card-horizontal
            color="primary"
            icon="ThumbsUpIcon"
            :statistic="confidence * 100"
            statistic-title="Confidence in %"
            class="metrics"
          />
          <p />
          <statistic-card-horizontal

            color="primary"
            icon="FileTextIcon"
            :statistic="noOfSentences()"
            statistic-title="Number of Sentences Analyzed"
            class="metrics"
          />
          <b-modal
            id="modal-select2"
            title="Send Product Feedback"
            ok-title="submit"
            cancel-variant="outline-secondary"
            @ok="handleFeedbackFormSubmit"
          >
            <b-form
              ref="form"
              @submit="handleFeedbackFormSubmit"
            >
              <b-form-group
                label="Choose feedback Category"
                label-for="vue-select"
              >
                <v-select
                  id="vue-select"
                  v-model="feedbackType"
                  dir="ltr'"
                  :options="option"
                />
              </b-form-group>
              <label for="textarea-default">Tell us what you think. We promise to act on your feedback to make Heu even more awesome.</label>
              <b-form-textarea
                id="textarea-default"
                v-model="feedbackText"
                placeholder="Type Your Feedback"
                rows="4"
              />
            </b-form>
          </b-modal>
        </div>
        </b-col>
      </b-row>
    </section>
  </div>
</template>

<script>
import {
  BImg, BFormTextarea, BModal, BForm, BFormGroup, BFormSpinbutton, BSpinner, BButtonGroup, VBTooltip, BButton, BRow, BCol,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
// import StatisticCardVertical from '@core/components/statistics-cards/StatisticCardVertical.vue'
import StatisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import db from '@/store/firestoreDB'
import QuillEditorCustom from './components/QuillEditorCustom.vue'

const LayoutVertical = () => import('@/layouts/vertical/LayoutVertical.vue')

export default {
  components: {
    BImg,
    BFormTextarea,
    BModal,
    BForm,
    BFormGroup,
    vSelect,
    BFormSpinbutton,
    BSpinner,
    BButtonGroup,
    BButton,
    BRow,
    BCol,
    // AppTour,

    AppCollapseItem,
    AppCollapse,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    QuillEditorCustom,
    // StatisticCardVertical,
    StatisticCardHorizontal,
    // HeaderOnePageTwo,
    // Layouts
    LayoutVertical,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
    forCallback(el, binding) {
      // eslint-disable-next-line prefer-const
      let element = binding.value
      // eslint-disable-next-line prefer-destructuring
      let key = element.key
      let len = 0

      if (Array.isArray(element.array)) {
        len = element.array.length
      } else if (typeof element.array === 'object') {
        // eslint-disable-next-line prefer-const
        let keys = Object.keys(element.array)
        key = keys.indexOf(key)
        len = keys.length
      }

      if (key === len - 1) {
        if (typeof element.callback === 'function') {
          element.callback()
        }
      }
    },
  },
  data() {
    return {
      totalSentenceCount: 0,
      // Bias Detail Data
      collapseType: 'margin',
      resultsShowing: false,
      showStandbyimage: true,
      showNoBiasDetectedimage: false,
      biasDetails: this.$store.state.runtimeData.biasDetails,
      learnmore: false,
      showless: false,
      // Spinner
      processing: false,
      // Modal
      feedbackType: 'Suggestion',
      option: ['Error Report', 'Exciting', 'Usability', 'Suggestion'],
      name: '',
      feedbackText: '',

      // User Customization
      confidence: 0.95,
      userSelectedBiases: [],

      // Assets
      // eslint-disable-next-line global-require
      standbyImg: require('@/assets/images/app/nothingtoreview.png'),
      // eslint-disable-next-line global-require
      noBiasDetectedImg: require('@/assets/images/app/nobiasfound.png'),
      steps: [
        {
          target: '#findbutton',
          header: {
            title: 'Card',
          },
          content: 'This card contains demo for the tour.',
        },
        {
          target: '#tour-card .icon-code',
          header: {
            title: 'View demo Source',
          },
          content: 'If you ever find your self confused, you can click on this code icon to check the source of current demo.',
        },
        {
          target: '#tour-card .btn',
          header: {
            title: 'Trigger Tour',
          },
          content: 'You can click on this button to trigger the tour.',
        },
      ],
    }
  },
  computed: {
    predictions() {
      const i = this.$store.state.runtimeData.tokenPredictions
      const j = i.filter(x => x.scores[0] !== Boolean)
      return j.filter(pred => pred.scores[0] > this.confidence)
    },
  },
  watch: {
  },
  mounted() {
    /*     window.setInterval(() => {
      // this.fetchPredictions()
    }, 30000) */
  },
  methods: {
    fetchPredictions() {
      const tokenPredictions = []
      // eslint-disable-next-line no-restricted-syntax
      for (const token of this.$store.state.runtimeData.tokens) {
        if (token !== '' && token.length > 15) {
          this.processing = true
          this.showStandbyimage = false
          this.queryCloudRun(this.cleanUpToken(token)).then(response => {
            tokenPredictions.push(response)
            this.processing = false
            if (this.predictions.length === 0) {
              this.showNoBiasDetectedimage = true
            } else {
              this.showNoBiasDetectedimage = false
            }
          })
        }
      }
      this.$store.commit('addTokenPredictions', tokenPredictions)
    },
    async query(payload) {
      const response = await fetch(
        'https://api-inference.huggingface.co/models/valhalla/distilbart-mnli-12-6',
        {
          headers: { Authorization: 'Bearer api_ExMQxKwvVzVAcDiWsqdLBbbohWbWqKFvGd' },
          method: 'POST',
          body: JSON.stringify(payload),
        },
      )
      const result = await response.json()
      return result
    },
    async querylocal(payload) {
      const response = await fetch(`http://localhost:8000/predict/v2/${payload}`)
      if (!response.ok) {
        throw new Error('Not 2xx response')
      }
      const result = await response.json()
      return result
    },
    async querylocalv2(payload) {
      const response = await fetch(`http://localhost:5000/predict/${payload}`)
      if (!response.ok) {
        throw new Error('Not 2xx response')
      }
      const result = await response.json()
      return result
    },
    async queryCloudRun(payload) {
      const response = await fetch(`https://heuapi-5uvuuqt7qq-uc.a.run.app/predict/${payload}`)
      if (!response.ok) {
        throw new Error('Not 2xx response')
      }
      const result = await response.json()
      return result
    },
    cleanUpToken(token) {
      let cleanToken = token
      // Remove beginning question mark
      if (token.charAt(0) === '?') {
        cleanToken = cleanToken.substring(1)
      }
      return cleanToken
    },
    sentenceChunck(sent) {
      this.resultsShowing = true
      this.showStandbyimage = false
      if (sent !== undefined) {
        // this.visibleTokens.push(sent)
        return `${sent.substring(0, 35)}...`
      }
      return null
    },
    noOfSentences() {
      const { tokens } = this.$store.state.runtimeData
      if (tokens[tokens.length - 1] === '') {
        this.totalSentenceCount = this.$store.state.runtimeData.tokens.length - 1
        return this.$store.state.runtimeData.tokens.length - 1
      }
      this.totalSentenceCount = this.$store.state.runtimeData.tokens.length
      return this.$store.state.runtimeData.tokens.length
    },
    biasChunck(bias) {
      return `• ${bias}`
    },
    getBiasDetail(bias) {
      if (this.biasDetails[bias]) {
        return this.biasDetails[bias]
      }
      return this.biasDetails.Default
    },
    clearPredictions() {
      this.$store.commit('addTokenPredictions', [])
    },
    score() {
      if (this.$store.state.runtimeData.tokens.length === 0 || this.resultsShowing === false) {
        return 0
      }
      const yourScore = (((this.totalSentenceCount - this.predictions.length) / (this.totalSentenceCount)) * 100)
      if (Number.isNaN(yourScore)) {
        return 0
      }
      return yourScore.toFixed(0)
    },
    topOccuringBias() {
      if (typeof this.predictions !== 'undefined') {
        const biases = []
        // eslint-disable-next-line no-restricted-syntax
        for (const pred of this.predictions) {
          biases.push(pred.labels[0])
        }
        return biases.sort((a, b) => biases.filter(v => v === a).length
        - biases.filter(v => v === b).length).pop()
      } return '--'
    },
    logFeedback(token, bias, confidence, feedbackType) {
      const today = new Date()
      const date = `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`
      const time = `${today.getHours()}:${today.getMinutes()}:${today.getSeconds()}`
      const dateTime = `${date} ${time}`

      this.$store.commit('addFeedback', {
        token, bias, confidence, feedbackType, dateTime,
      })

      const feedback = {
        token, bias, confidence, feedbackType, dateTime,
      }

      db.collection('feedback').add(feedback).then(() => {

      })

      // console.log(this.$store.state.runtimeData.feedbacks)
    },
    showToast(variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Notification',
          icon: 'BellIcon',
          text: '👋 Thanks for the feedback',
          variant,
        },
      })
    },
    showToastNoBiasFound(variant, icon) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Notification',
          icon,
          text: 'Awesome, No Biases found',
          variant,
        },
      })
    },
    showHighlight() {
      this.processing = false
      this.$root.$refs.QuillEditor.HighlightBiasInEditor()
    },
    clearQuillEditor() {
      // this.$root.$refs.QuillEditor.clearEditior()
      window.location.reload()
    },
    handleFeedbackFormSubmit() {
      const today = new Date()
      const date = `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`
      const time = `${today.getHours()}:${today.getMinutes()}:${today.getSeconds()}`
      const dateTime = `${date} ${time}`

      const feedback = {
        feedbackType: this.feedbackType, feedbackText: this.feedbackText, dateTime, Source: 'App page',
      }
      this.$store.commit('addWrittenFeedback', feedback)
      db.collection('feedback').add(feedback).then(() => {

      })
    },
    track() {
      this.$gtag.pageview({ page_path: '/app' })
      this.$gtag.pageview('/app')
      this.$gtag.pageview(this.$route)
    },
    tourStart() {
      this.$tours.vuexyTour.start()
    },

  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/quill.scss';
// import core styles
@import '@core/scss/core.scss';
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap');

.ql-container {
  height: auto;
  border: none !important;
}
.ql-toolbar{
    border: none !important;
    height: 70px;
    color: #fff;
}
 .ql-editor {
     font-family: Inter, sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: normal;
    line-height: 36px !important;
    text-transform: none;
    color: rgb(14, 16, 26);
}

.card .card-title {
  font-size: 10px;
  font-family: 'Lato', sans-serif;
}
.collapse-margin .card {
    margin-top: 1.7rem;
    margin-bottom: 0.71rem;
    box-shadow: 0px 2px 8px 0px #1a213433, 0px 0px 0.5px 0px #000000b2 !important;
    border-radius: 0.9rem;
    border-bottom: 0 solid transparent !important;
}
.accordion-title{
  font-family: 'Inter', sans-serif !important;
  font-size: 15px !important;
  color: #242424;
}
.accordion-bias{
  font-family: 'Inter', sans-serif !important;
  font-size: 15px !important;
  font-weight: bolder;
  cursor: pointer;
    //box-shadow: 10px 0 10px 0px #000000, 10px 0 10px 0px #000000;
}
.detail-background{
  border-radius: 0 0 15px 15px;
  background-color: rgb(248, 248, 248);
}
.detail-title {
  font-family: 'Poppins', sans-serif !important;
  font-weight: 600;
  margin-bottom: 10px;
  font-size: 15px;
  //color: #E9C46A;
  color: black;
}
.detail-body{
  font-family: 'Poppins', sans-serif !important;
  color: #929395;
  font-size: 14px;
  line-height: 20px;
}
.find {
    margin-top: 50px;
}
.expander {
  cursor: pointer;
}
.sequence {
  //color: red;
}
.progressbar {
  margin-top: 20px;
}
.metrics{
  margin-bottom: 0rem;
  margin-top: 0rem;
  border-color: black;
}
.metrics-container{
  font-family: 'Inter', sans-serif !important;
  background-color: #ffffff;
  height:1000px;
  padding-right: 10px;
  padding-left: 10px;
  //box-shadow: 0px 2px 8px 0px #1a213433, 0px 0px 0.5px 0px #000000b2 !important;
}
.metrics-title{
  font-family: 'Inter', sans-serif !important;
}
.metrics-value{
  font-family: 'Poppins', sans-serif !important;
  font-size: 20px !important;
}
.pulse {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
}
/* .sendfeedback {
  margin-left: 120px;
  margin-right: 50px;
} */
html, body {
  overflow-y:auto;
  height:100%;
}

</style>
