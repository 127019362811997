<template>
  <quill-editor
    ref="myQuillEditor"
    v-model="content"
    :options="editorOption"
  />
</template>

<script>
import { quillEditor } from 'vue-quill-editor'

export default {
  components: {
    quillEditor,
  },
  // eslint-disable-next-line vue/require-prop-types
  props: ['predictions'],
  data() {
    return {
      editorOption: {
        modules: {
          toolbar: [
            // [{ header: [1, 2, false] }],
            ['bold', 'italic', 'underline', 'strike'],
            [{ list: 'ordered' }, { list: 'bullet' }],
            [{ indent: '-1' }, { indent: '+1' }],
            ['clean'],
          ],
        },
        placeholder: 'Type or paste your text here..',
      },
      content: '',
      delta: undefined,
      tokens: undefined,
      htmlFromQuillEditor: '',
      originalUnformatted: '',
      original: true,
      highlighted: '',
    }
  },
  watch: {
    content() {
      this.delta = this.$refs.myQuillEditor.quill.getText()
      this.htmlFromQuillEditor = this.$refs.myQuillEditor.quill.root.innerHTML
      this.tokens = this.delta.replace(/\n/g, '').split('.')
      this.$store.commit('addTokens', this.tokens)
    },
  },
  created() {
    this.$root.$refs.QuillEditor = this
  },
  methods: {
    HighlightBiasInEditor() {
      this.$refs.myQuillEditor.quill.root.innerHTML = ''

      if (this.original) {
        this.original = false
        this.originalUnformatted = this.htmlFromQuillEditor
      }
      // eslint-disable-next-line no-restricted-syntax
      for (const pred of this.predictions) {
        this.htmlFromQuillEditor = this.htmlFromQuillEditor.replace(pred.sequence, `<span style="background-color:#ffff3c; padding: 3px; border-radius: 5px;">${pred.sequence}</span>`)
      }
      this.$refs.myQuillEditor.quill.clipboard.dangerouslyPasteHTML(0, this.htmlFromQuillEditor)
    },
    clearEditior() {
      this.$refs.myQuillEditor.quill.root.innerHTML = ''
    },
  },
}
</script>

<style>
.ql-editor.ql-blank::before{
 font-family: Lato, sans-serif;
 font-size: 15px;
 font-style: normal;
 font-weight: 300;
 letter-spacing: normal;
 line-height: 27px;
 text-transform: none;
 color: #323339;
}
mark{
 background-color:#c0ffc8;
}
</style>
